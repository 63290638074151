import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types';


const AppContext = createContext(null);

const AppProvider = ({ children }) => {
  const [activeHeaderIndex, setActiveHeaderIndex] = useState()
  const [ExitModal, setExitModal] = useState({
    type: null,
    href: null,
    showModal: false
  });
  const [mobileInputFocus, setMobileInputFocus] = useState(false)
  const [zoomed, setZoomed] = useState(false)


  const enableExitModal = (event, href, type = "default") => {
    event.preventDefault()

    //type = default, xywav or ihf
    href && setExitModal({ type, href, showModal: true })
  }

  const [tooltip, setTooltip] = useState({
    showTooltip: false,
    term: null, 
    triggerPosition: null
  })

  return (
    <AppContext.Provider
      value={{
        activeHeaderIndex,
        setActiveHeaderIndex,
        ExitModal,
        setExitModal,
        enableExitModal,
        tooltip,
        setTooltip,
        mobileInputFocus,
        setMobileInputFocus,
        zoomed,
        setZoomed
      }}
    >
      {children}
    </AppContext.Provider>
  )
};

AppProvider.propTypes = {
  children: PropTypes.node,
};

export default AppContext;

export { AppProvider };
